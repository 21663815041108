import React from "react"
import Menu from "./Menu";
import {Col, Container, Row} from "react-bootstrap";
import {SocialIcon} from "react-social-icons";
import './layout.scss'

export default function Layout({children}) {
    return (
        <div className="container-fluid p-0">
            <Menu/>
            <div id="main">
                <Container>
                    {children}
                </Container>
            </div>
            <Container className="App-footer" fluid>
                <Row>
                    <Col>
                        <SocialIcon title="Sensei Ravey"
                                    url="https://www.facebook.com/pages/Sensei-Graham-Ravey/117797741595321?fref=ts&ref=br_tf"/>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class WhyKarateKidRachelIsASuperbInspiration extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Why karate kid Rachel is a superb inspiration, The Star newspaper UK</h1>
                <StaticImage src="../../images/karatekid/ResizedImage600727-4143604.jpg" alt="Karate Kid] 1"/>
            </Layout>);
    }
}

export default WhyKarateKidRachelIsASuperbInspiration;
